// action types

import ApiService from "@/core/services/api.service";

export const GET_CONTENTS = "getContents";

const state = {
  contents: [],
};

const getters = {
  contents(state) {
    return state.contents;
  },
  getByCodename: (state) => (codename) => {
    if (codename === "") return state.contents;
    return state.contents.filter((elem) => elem.codename === codename);
  },
  getById: (state) => (id) => {
    if (id === "") return state.contents;
    return state.contents.find((elem) => elem.id === id);
  },
};

const actions = {
  [GET_CONTENTS](context) {
    return new Promise(function (resolve, reject) {
      if (context.state.contents.length > 0) {
        resolve(context.state.contents);
      } else {
        ApiService.get("contents")
          .then(({ data }) => {
            context.commit(GET_CONTENTS, data);
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      }
    });
  },
};

const mutations = {
  [GET_CONTENTS](state, payload) {
    state.contents.splice(0, state.contents.length);
    payload.map((item) => state.contents.push(item));
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
