// action types

import ApiService from "@/core/services/api.service";

export const GET_REPORT = "getReport";
export const GET_CERTIFICATE = "getCertificate";
export const GET_DOWLOADABLE_REPORT = "GET_DOWLOADABLE_REPORT";

const state = {};

const getters = {};

const actions = {
  [GET_REPORT](context, { token, filename }) {
    return ApiService.download("report", { token }, filename, ".pdf");
  },
  [GET_CERTIFICATE](context, { token, filename }) {
    ApiService.download("certificate", { token }, filename, ".pdf")
      .then(() => {
        // context.commit(GET_CONTENTS, data);
      })
      .catch(() => {});
  },
  [GET_DOWLOADABLE_REPORT](context, { filename, token }) {
    return ApiService.post(`report`, { token }, { responseType: "arraybuffer" });
  },
};

const mutations = {};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
