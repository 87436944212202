// USA
import texts_error from "./fr/error.json";
import texts_general from "./fr/general.json";
import texts_auth from "./fr/auth.json";
import texts_home from "./fr/home.json";
import texts_about from "./fr/about.json";
import texts_privacy from "./fr/privacy.json";
import texts_gdpr from "./fr/gdpr.json";
import texts_eula from "./fr/eula.json";
import texts_validation from "./fr/validation.json";
import texts_countries from "./fr/countries.json";

export const locale = {
  ERROR: texts_error,
  AUTH: texts_auth,
  GENERAL: texts_general,
  HOME: texts_home,
  ABOUT: texts_about,
  PRIVACY: texts_privacy,
  GDPR: texts_gdpr,
  EULA: texts_eula,
  VALIDATION: texts_validation,
  COUNTRIES: texts_countries,
};
