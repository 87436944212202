// USA
import texts_error from "./it/error.json";
import texts_general from "./it/general.json";
import texts_auth from "./it/auth.json";
import texts_home from "./it/home.json";
import texts_about from "./it/about.json";
import texts_privacy from "./it/privacy.json";
import texts_gdpr from "./it/gdpr.json";
import texts_eula from "./it/eula.json";
import texts_validation from "./it/validation.json";
import texts_countries from "./it/countries.json";

export const locale = {
  ERROR: texts_error,
  AUTH: texts_auth,
  GENERAL: texts_general,
  HOME: texts_home,
  ABOUT: texts_about,
  PRIVACY: texts_privacy,
  GDPR: texts_gdpr,
  EULA: texts_eula,
  VALIDATION: texts_validation,
  COUNTRIES: texts_countries,
};
