const i18nService = {
  defaultLanguage: "pt_BR",

  languages: [
    {
      lang: "en",
      name: "English",
      flag: process.env.BASE_URL + "media/svg/flags/EN.svg",
      // flag: process.env.BASE_URL + "media/svg/flags/226-united-states.svg",
    },
    // {
    //   lang: "ch",
    //   name: "Mandarin",
    //   flag: process.env.BASE_URL + "media/svg/flags/034-china.svg"
    // },
    {
      lang: "es",
      name: "Español",
      flag: process.env.BASE_URL + "media/svg/flags/ES.svg",
      // flag: process.env.BASE_URL + "media/svg/flags/128-spain.svg",
    },
    // {
    //   lang: "jp",
    //   name: "Japanese",
    //   flag: process.env.BASE_URL + "media/svg/flags/063-japan.svg"
    // },
    // {
    //   lang: "de",
    //   name: "German",
    //   flag: process.env.BASE_URL + "media/svg/flags/162-germany.svg",
    // },
    // {
    //   lang: "fr",
    //   name: "French",
    //   flag: process.env.BASE_URL + "media/svg/flags/195-france.svg",
    // },
    // {
    //   lang: "it",
    //   name: "Italian",
    //   flag: process.env.BASE_URL + "media/svg/flags/013-italy.svg",
    // },
    {
      lang: "pt_BR",
      name: "Português",
      flag: process.env.BASE_URL + "media/svg/flags/PT.svg",
      // flag: process.env.BASE_URL + "media/svg/flags/011-brazil.svg",
    },
  ],

  /**
   * Keep the active language in the localStorage
   * @param lang
   */
  setActiveLanguage(lang) {
    let current = this.getActiveLanguage();
    if (current !== lang) {
      localStorage.setItem("language", lang);
    }
  },

  /**
   * Keep the active language in the localStorage
   * @param lang
   */
  setActiveLanguageAndReloadIfNeeds(lang) {
    let current = this.getActiveLanguage();
    if (current !== lang) {
      localStorage.setItem("language", lang);
      window.location.reload();
    }
  },

  /**
   * Get the current active language
   * @returns {string | string}
   */
  getActiveLanguage() {
    return localStorage.getItem("language") || this.defaultLanguage;
  },
};

export default i18nService;
