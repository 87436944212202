import i18n from "@/core/plugins/vue-i18n";

const LAUtils = {
  /**
   * Set the default HTTP request headers
   */

  /**
   * Set the default HTTP request headers
   */
  getContentTranslation(codename, path) {
    let prefix = `CONTENTS.${codename}.${path}`.toUpperCase();
    return i18n.tc(prefix);
  },

  backgroundImage(path) {
    return process.env.BASE_URL + (path || "");
  },
  contentImagePath(photo_url) {
    photo_url = (photo_url || "").toLowerCase();
    return process.env.VUE_APP_API_URL + photo_url + "?r=" + Math.random();
  },
  slugIt(str) {
    str = str.replace(/^\s+|\s+$/g, ""); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    var from = "åàáãäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to = "aaaaaaeeeeiiiioooouuuunc------";

    for (var i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
    }

    str = str
      .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
      .replace(/\s+/g, "-") // collapse whitespace and replace by -
      .replace(/-+/g, "-") // collapse dashes
      .replace(/^-+/, "") // trim - from start of text
      .replace(/-+$/, ""); // trim - from end of text

    return str;
  },
};

export default LAUtils;
