// action types

import ApiService from "@/core/services/api.service";

export const GET_PRESET_DATA_FREE_CONTENT = "GET_PRESET_DATA_FREE_CONTENT";
export const GET_SAVE_DATA_FREE_CONTENT = "GET_SAVE_DATA_FREE_CONTENT";
export const UPDATE_SAVE_DATA_FREE_CONTENT = "UPDATE_SAVE_DATA_FREE_CONTENT";
export const COMMIT_FREE_CONTENT = "COMMIT_FREE_CONTENT";

const state = {
  saveContent: {},
  codename: "",
  token: "",
};

const getters = {
  freeContentSaveContent(state) {
    return state.saveContent;
  },
  freeContentToken(state) {
    return state.token || "";
  },
};

const actions = {
  [GET_PRESET_DATA_FREE_CONTENT](context, codename) {
    return ApiService.get(`free-contents/get-data/${codename}`);
  },

  [GET_SAVE_DATA_FREE_CONTENT](context, codename) {
    return ApiService.get(`free-contents/get-save-data/${codename}`);
  },
  [UPDATE_SAVE_DATA_FREE_CONTENT](context, { saveContent, codename }) {
    context.commit(UPDATE_SAVE_DATA_FREE_CONTENT, {
      saveContent,
      codename,
    });
  },
  [COMMIT_FREE_CONTENT](context) {
    if (context.state.codename !== "") {
      return ApiService.post(`contents/create-free-content/${context.state.codename}`, {
        save_content: context.state.saveContent,
      }).then((response) => {
        context.commit(COMMIT_FREE_CONTENT, {
          token: response.data.token || "",
        });
      });
    }
  },
};

const mutations = {
  [UPDATE_SAVE_DATA_FREE_CONTENT](state, { saveContent, codename }) {
    state.saveContent = saveContent;
    state.codename = codename;
  },
  [COMMIT_FREE_CONTENT](state, { token }) {
    state.token = token;
    state.codename = "";
    state.saveContent = {};
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
