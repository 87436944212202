// USA
import texts_error from "./pt/error.json";
import texts_general from "./pt/general.json";
import texts_auth from "./pt/auth.json";
import texts_home from "./pt/home.json";
import texts_about from "./pt/about.json";
import texts_privacy from "./pt/privacy.json";
import texts_gdpr from "./pt/gdpr.json";
import texts_eula from "./pt/eula.json";
import texts_validation from "./pt/validation.json";
import texts_countries from "./pt/countries.json";

export const locale = {
  ERROR: texts_error,
  AUTH: texts_auth,
  GENERAL: texts_general,
  HOME: texts_home,
  ABOUT: texts_about,
  PRIVACY: texts_privacy,
  GDPR: texts_gdpr,
  EULA: texts_eula,
  VALIDATION: texts_validation,
  COUNTRIES: texts_countries,
};
