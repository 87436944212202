import ApiService from "@/core/services/api.service";

export const GET_BUNDLES = "GET_BUNDLES";
export const GET_BUNDLE = "GET_BUNDLE";
export const SET_BUNDLE_TYPE = "SET_BUNDLE_TYPE";

const state = {
  bundles: [],
  currentType: "",
  detailedBundles: {},
  bundle: {
    codename: "",
    photo_url: "",
    content_id: 0,
    id: 0,
    price: 0,
    discount: 0,
    type: "",
    texts: {
      name: "0",
    },
    quantity: 0,
    contents: [],
    bundle_categories: [],
  },
};

const getters = {
  bundles(state) {
    return state.bundles;
  },

  bundle(state) {
    return state.bundle ?? {};
  },

  bundleCurrentType(state) {
    return state.currentType;
  },
  programs(state) {
    return state.bundles.filter((elem) => elem.type === "program");
  },
  maps(state) {
    return state.bundles.filter((elem) => elem.type === "map");
  },
  getByContentType: (state) => (type) => {
    if (type !== "program" && type !== "map") return state.bundles;
    return state.bundles.filter((elem) => elem.type === type);
  },
  getTypeByCodename: (state) => (codename) => {
    if (codename === "" || codename === "bundle" || codename === "bundles") return state.bundles;
    return state.bundles.filter((elem) => elem.codename === codename);
  },
};

const actions = {
  [GET_BUNDLES](context) {
    if (context.state.bundles.length === 0) {
      return new Promise((resolver, rejects) => {
        ApiService.get("bundle")
          .then(({ data }) => {
            context.commit(GET_BUNDLES, data);
            resolver(context.state.bundles);
          })
          .catch(() => {});
      });
    } else {
      return new Promise((resolver, rejects) => {
        resolver(context.state.bundles);
      });
    }
  },
  [GET_BUNDLE](context, codename) {
    return new Promise((resolver, rejects) => {
      if (context.state.detailedBundles[codename] !== undefined) {
        context.commit(GET_BUNDLE, context.state.detailedBundles[codename]);
        resolver(context.state.bundle);
      } else {
        ApiService.get("bundle/codename/" + codename)
          .then(({ data }) => {
            context.commit(GET_BUNDLE, data[0]);
            resolver(context.state.bundle);
          })
          .catch(() => {});
      }
    });
  },
  [SET_BUNDLE_TYPE](context, type) {
    context.commit(SET_BUNDLE_TYPE, type);
  },
};

const mutations = {
  [GET_BUNDLES](state, payload) {
    state.bundles.splice(0, state.bundles.length);
    payload.map((elem) => {
      state.bundles.push(elem);
    });
  },
  [GET_BUNDLE](state, payload) {
    state.detailedBundles[payload.codename] = JSON.parse(JSON.stringify(payload));
    Object.assign(state.bundle, payload);
  },
  [SET_BUNDLE_TYPE](state, payload) {
    state.currentType = payload;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
