// action types

import ApiService from "@/core/services/api.service";
import Encryption from "@/core/services/Encryption";
import store from "@/core/services/store/index";

export const CHECKOUT_PROCESS = "CHECKOUT_PROCESS";
export const CHECKOUT_PROCESS3 = "CHECKOUT_PROCESS";

const state = {
  user_personal_info: {},
};

const getters = {
  currentUserPersonalInfo(state) {
    return state.user_personal_info;
  },
};

const actions = {
  [CHECKOUT_PROCESS](context, payload) {
    let encryption = new Encryption();
    let checkoutData = encryption.encrypt(JSON.stringify(payload), store.getters.currentUser.email);
    return new Promise((onSuccess, onFail) => {
      ApiService.post("purchases/process2", {
        checkoutData,
      })
        .then((response) => {
          // context.commit(GET_MY_ORDERS, response);
          onSuccess(response.data);
        })
        .catch((response) => {
          onFail(response);
        });
    });
  },
};

const mutations = {
  // [SET_PERSONAL_INFO](state, user_personal_info) {
  //   state.user_personal_info = user_personal_info;
  // },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
