import aes from "vue-crypt/src/aes";

const EXPIRES_IN = "expires_in";
const ACCESS_TOKEN = "access_token";
const REFRESH_TOKEN = "refresh_token";
const USER = "user";
export const getAccessToken = () => {
  return window.sessionStorage.getItem(ACCESS_TOKEN);
};
export const getRefreshToken = () => {
  return window.sessionStorage.getItem(REFRESH_TOKEN);
};
export const isAuthenticated = () => {
  return !!window.sessionStorage.getItem(ACCESS_TOKEN);
};

export const getExpiresIn = () => {
  return parseInt(window.sessionStorage.getItem(EXPIRES_IN));
};

export const saveUser = (user) => {
  let strfy = JSON.stringify(user);
  window.sessionStorage.setItem(USER, aes.encrypt(strfy));
};

export const getUser = () => {
  let strfy = window.sessionStorage.getItem(USER);
  if (strfy === null) return null;
  return JSON.parse(aes.decrypt(strfy));
};
export const destroyUser = () => {
  return window.sessionStorage.removeItem(USER);
};

export const saveToken = (token) => {
  window.sessionStorage.setItem(REFRESH_TOKEN, token[REFRESH_TOKEN]);
  window.sessionStorage.setItem(ACCESS_TOKEN, token[ACCESS_TOKEN]);
  window.sessionStorage.setItem(EXPIRES_IN, token[EXPIRES_IN]);
};

export const destroyToken = () => {
  window.sessionStorage.removeItem(REFRESH_TOKEN);
  window.sessionStorage.removeItem(ACCESS_TOKEN);
  window.sessionStorage.removeItem(EXPIRES_IN);
  window.sessionStorage.removeItem(USER);
};

export default {
  saveUser,
  getUser,
  destroyUser,
  isAuthenticated,
  getExpiresIn,
  getAccessToken,
  getRefreshToken,
  saveToken,
  destroyToken,
};
