// action types

import ApiService from "@/core/services/api.service";

export const GET_MY_ORDERS = "GET_MY_ORDERS";
export const GET_MY_ORDER = "GET_MY_ORDER";

// mutation types

const state = {
  myOrders: [],
  paginator: {
    total: 0,
    count: 0,
    per_page: 0,
    current_page: 0,
    total_pages: 0,
  },

  perPage: 5,
};

const getters = {
  myOrders(state) {
    return state.myOrders;
  },
  currentMyOrdersPaginator(state) {
    return state.paginator;
  },
};

const actions = {
  [GET_MY_ORDERS](context, { page }) {
    return new Promise((onSuccess, onFail) => {
      ApiService.query("purchases", {
        page,
        per_page: context.state.perPage,
      })
        .then((response) => {
          context.commit(GET_MY_ORDERS, response);
          onSuccess(context.state.myOrders);
        })
        .catch((response) => {
          onFail(response);
        });
    });
  },
  [GET_MY_ORDER](context, id) {
    return new Promise((onSuccess, onFail) => {
      ApiService.get(`purchases/${id}`)
        .then((response) => {
          onSuccess(response.data);
        })
        .catch((response) => {
          onFail(response);
        });
    });
  },
};

const mutations = {
  [GET_MY_ORDERS](state, response) {
    state.myOrders.splice(0, state.myOrders.length);
    state.paginator = response.data.pagination;

    response.data.data.map((elem) => {
      state.myOrders.push(elem);
    });
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
