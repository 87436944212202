// action types

import ApiService from "@/core/services/api.service";
import _ from "lodash";

export const GET_TAGS = "getTags";
export const GET_TAGS_FULL_LIST = "getTagsFullList";
export const CREATE_UPDATE_TAG = "createUpdateTag";
export const GET_TAG_DETAILED = "GET_TAG_DETAILED";
export const GET_TAGS_FROM_TOKEN = "getTagsFromToken";
export const ADD_TAG_TO_TOKEN = "ADD_TAG_TO_TOKEN";
export const REMOVE_TAG_TO_TOKEN = "REMOVE_TAG_TO_TOKEN";
export const REMOVE_TAG = "REMOVE_TAG";
export const GET_TAG_REPORT = "GET_TAG_REPORT";
export const GET_DOWLOADABLE_TAG_REPORT = "GET_DOWLOADABLE_TAG_REPORT";

const state = {
  fullList: [],
  tags: [],
  pagination: {
    total: 0,
    count: 0,
    per_page: 0,
    current_page: 0,
    total_pages: 0,
  },
  filter: {
    search: null,
    perPage: 30,
    content: "",
  },
};

const getters = {
  tags(state) {
    return state.tags;
  },
  tagsFullList(state) {
    return state.fullList;
  },
  tagsFullListByContentId: (state) => (id) => {
    if (id === "") return state.fullList;
    return state.fullList.filter((elem) => elem.content_id === id);
  },
  tagsPagination(state) {
    return state.pagination;
  },
  tagsFilter(state) {
    return state.filter;
  },
  getById: (state) => (id) => {
    if (id === "") return state.fullList;
    id = parseInt(id);
    return state.fullList.find((elem) => elem.id === id);
  },
};

const actions = {
  [GET_TAGS](context, query) {
    return ApiService.query("token-tags/list", query).then(({ data }) => {
      context.commit(GET_TAGS, data);
    });
  },
  [GET_TAG_DETAILED](context, payload) {
    return ApiService.get("token-tags/" + payload.id);
  },
  [GET_TAGS_FROM_TOKEN](context, payload) {
    return ApiService.get("token-tags/token/" + payload.id);
  },

  [ADD_TAG_TO_TOKEN](context, payload) {
    return ApiService.post("token-tags/token/" + payload.tokenId + "/add-tag/" + payload.tagId);
  },
  [REMOVE_TAG_TO_TOKEN](context, payload) {
    return ApiService.post("token-tags/token/" + payload.tokenId + "/remove-tag/" + payload.tagId);
  },
  [GET_TAGS_FULL_LIST](context, payload) {
    // eslint-disable-next-line prettier/prettier
    return new Promise((updateResolver, updateRejecter) => {
      if (context.state.fullList.length > 0) {
        updateResolver(context.state.fullList);
      } else {
        ApiService.get("token-tags/full-list").then(({ data }) => {
          context.commit(GET_TAGS_FULL_LIST, data);
          updateResolver(data);
        });
      }
    });
  },
  [CREATE_UPDATE_TAG](context, payload) {
    return new Promise((updateResolver, updateRejecter) => {
      // eslint-disable-next-line prettier/prettier
      ApiService.post("token-tags/update" , payload)
        .then(({ data }) => {
          context.commit(CREATE_UPDATE_TAG, data);
          updateResolver(data);
        })
        .catch(({ response }) => {
          updateRejecter(response);
        });
    });
  },

  [GET_TAG_REPORT](context, { id, name }) {
    return ApiService.download("token-tags/token/" + id + "/report", {}, name, ".pdf");
  },
  [GET_DOWLOADABLE_TAG_REPORT](context, { name, id }) {
    return ApiService.post("token-tags/token/" + id + "/report", {}, { responseType: "arraybuffer" });
  },

  [REMOVE_TAG](context, payload) {
    return new Promise((updateResolver, updateRejecter) => {
      // eslint-disable-next-line prettier/prettier
      ApiService.post("token-tags/remove" , payload)
        .then(({ data }) => {
          context.commit(REMOVE_TAG, payload);
          updateResolver(data);
        })
        .catch(({ response }) => {
          updateRejecter(response);
        });
    });
  },
};

const mutations = {
  [GET_TAGS](state, payload) {
    state.tags.splice(0, state.tags.length);
    payload.data.map((item) => state.tags.push(item));
    state.pagination.total = payload.pagination.total;
    state.pagination.count = payload.pagination.count;
    state.pagination.per_page = payload.pagination.per_page;
    state.pagination.current_page = payload.pagination.current_page;
    state.pagination.total_pages = payload.pagination.total_pages;
  },
  [GET_TAGS_FULL_LIST](state, payload) {
    state.fullList.splice(0, state.fullList.length);
    payload.map((item) => state.fullList.push(item));
  },
  [REMOVE_TAG](state, payload) {
    payload.id = parseInt(payload.id);
    _.remove(state.tags, (tag) => tag.id === payload.id);
    _.remove(state.fullList, (tag) => tag.id === payload.id);
  },
  [CREATE_UPDATE_TAG](state, payload) {
    payload.id = parseInt(payload.id);
    let tag = state.tags.find((tag) => tag.id === payload.id);
    if (tag) {
      // tag.name = payload.name;
      Object.assign(tag, payload);
    } else {
      state.tags.push(payload);
    }

    let tagF = state.fullList.find((tag) => tag.id === payload.id);
    if (tagF) {
      // tagF.name = payload.name;
      Object.assign(tagF, payload);
    } else {
      state.fullList.push(payload);
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
