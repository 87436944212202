import ApiService from "@/core/services/api.service";
import BearerService from "@/core/services/bearer.service";
import merge from "deepmerge";
import store from "@/core/services/store/index";
import router from "./../../../router";
import { OVERRIDE_PAGE_LAYOUT_CONFIG } from "@/core/services/store/config.module";
import HtmlClass from "@/core/services/htmlclass.service";
import objectPath from "object-path";
import { COMMIT_FREE_CONTENT } from "@/core/services/store/free-content.module";
import { ADD_BODY_CLASSNAME } from "@/core/services/store/htmlclass.module";
import TheToaster from "@/core/services/the-toaster";

const state = {};

const getters = {};

const actions = {
  send(context, payload) {
    return new Promise((loginResolver, loginRejects) => {
      ApiService.post("contact/send", payload)
        .then(({ data }) => {
          loginResolver();
        })
        .catch(({ response }) => {
          loginRejects();
        });
    });
  },
};
const mutations = {};
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
