<template>
  <router-view></router-view>
</template>

<style lang="scss">
// 3rd party plugins css
@import "~bootstrap-vue/dist/bootstrap-vue.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";
@import "~socicon/css/socicon.css";
@import "~animate.css";
@import "~@fortawesome/fontawesome-free/css/all.css";
@import "~line-awesome/dist/line-awesome/css/line-awesome.css";
@import "assets/plugins/flaticon/flaticon.css";
@import "assets/plugins/flaticon2/flaticon.css";
@import "assets/plugins/keenthemes-icons/font/ki.css";
@import "~sweetalert2/dist/sweetalert2.min.css";
// Main demo style scss
@import "assets/sass/style.vue";

// Check documentation for RTL css
// Update HTML with RTL attribute at public/index.html
/*@import "assets/css/style.vue.rtl";*/
</style>

<script>
// eslint-disable-next-line max-len
//https://epocanegocios.globo.com/Dinheiro/noticia/2019/11/medina-cidade-quebrada-onde-vivem-os-dois-homens-mais-ricos-do-mundo-bill-gates-e-jeff-bezos.html?fbclid=IwAR3ocg0Y86S3icu7Ddv4gwR4ejo8cal8FhKGi5OgKjMUnsYFkUFNKZQgDmM
//import {OVERRIDE_LAYOUT_CONFIG,OVERRIDE_PAGE_LAYOUT_CONFIG} from "@/core/services/store/config.module";

export default {
  name: "LifeAcademyVue",
  mounted() {
    /**
     * this is to override the layout config using saved data from localStorage
     * remove this to use config only from static json (@/core/config/layout.config.json)
     */
    // this.$store.dispatch(OVERRIDE_LAYOUT_CONFIG);
    // this.$store.dispatch(OVERRIDE_PAGE_LAYOUT_CONFIG, {
    //   self: {
    //     logo: {
    //       default: "media/logos/3rdparty-logos/gc.png",
    //       sticky: "media/logos/3rdparty-logos/gc.png"
    //     }
    //   }
    // });
  },
};
</script>
2260020374 b
