import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import BearerService from "@/core/services/bearer.service";
import TheToaster from "@/core/services/the-toaster";
import i18nService from "@/core/services/i18n.service";
import auth, { IDDLE_CHECKER, LOGOUT } from "@/core/services/store/auth.module";
import store from "@/core/services/store";
import router from "./../../router";

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  isWaitingOauth: false,
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = process.env.VUE_APP_API_URL;

    axios.interceptors.request.use(
      function (config) {
        // eslint-disable-next-line prettier/prettier
          config.headers["Authorization"] = `Bearer ${BearerService.getAccessToken()}`;
        config.headers["X-Language"] = i18nService.getActiveLanguage();

        if (auth.getters.getSettableWhiteLabel(auth.state)) {
          // eslint-disable-next-line prettier/prettier
            config.headers["X-WhiteLabel"] = auth.getters.getSettableWhiteLabel(auth.state);
        } else {
          delete config.headers["X-WhiteLabel"];
        }
        // if (config.url.substring(0, 5) === "oauth") {
        //   return config;
        // }
        store.dispatch("auth/" + IDDLE_CHECKER);
        config.url = `api/${config.url}`;
        // Nova.$emit("selfbi-show-wait", null);
        // // Do something before request is sent
        return config;
      },
      function (error) {
        // Do something with request error
        //Nova.$emit("selfbi-show-wait", 0);
        // TheToaster.error(error.message);
        //Nova.error("Request error: " + error.message);
        return Promise.reject(error);
      }
    );
    axios.interceptors.response.use(
      function (response) {
        if (response.config.url.includes("oauth/token")) {
          ApiService.isWaitingOauth = false;
        }
        return response;
      },
      function (error) {
        if (error.response == null) {
          router.push({ name: "500" });
          return null;
        }
        if (error.config.url.includes("oauth/token")) {
          ApiService.isWaitingOauth = false;
        }
        // eslint-disable-next-line
          if (error.response.status === 401) {
          store.dispatch("auth/" + LOGOUT).then(() => {
            TheToaster.error("ERROR.UNAUTHENTICATED");
          });
        } else {
          let errorData = error.response.data;
          let message = errorData?.message || error?.message || "";
          message += " ";
          switch (error.response.config.url) {
            case "oauth/token":
            case "api/oauth/token":
              TheToaster.error("ERROR.INVALID_LOGIN");
              break;
            default:
              message += " ";
              if (typeof errorData?.errors === "object") {
                let key = "";
                for (key in errorData.errors) {
                  if (Array.isArray(errorData.errors[key])) {
                    message +=
                      " " +
                      errorData.errors[key].reduce((elem) => {
                        return " -> " + elem + " ";
                      });
                  }
                }
              }
              // Nova.$emit("selfbi-show-wait", 0);
              // console.group("ERROR: " + errorData.message);
              // eslint-disable-next-line
                console.info(message);
              // eslint-disable-next-line
                console.info(error.response);
              TheToaster.error(message, false);
              break;
          }
        }

        return Promise.reject(error);
      }
    );

    Vue.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
    Vue.axios.defaults.headers.common["Content-Type"] = "application/json";
    Vue.axios.defaults.headers.common["Accept"] = "application/json,application/octet-stream,application/pdf";
  },

  async download(resource, params, filename = "file", extension = ".pdf") {
    if (resource.includes("oauth/token")) {
      ApiService.isWaitingOauth = true;
    } else {
      await ApiService.until(() => {
        return ApiService.isWaitingOauth === false;
      });
    }
    return new Promise((onSuccess, onErros) => {
      Vue.axios
        .post(resource, params, { responseType: "arraybuffer" })
        .then((response) => {
          let blob = new Blob([response.data], { type: response.headers.contentType });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", filename + extension);
          document.body.appendChild(link);
          link.click();
          link.remove();
          onSuccess({ blob, url });
        })
        .catch((e) => onErros(e));
    });
  },

  async query(resource, params) {
    if (resource.includes("oauth/token")) {
      ApiService.isWaitingOauth = true;
    } else {
      await ApiService.until(() => {
        return ApiService.isWaitingOauth === false;
      });
    }
    return Vue.axios.get(resource, { params });
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  async get(resource) {
    if (resource.includes("oauth/token")) {
      ApiService.isWaitingOauth = true;
    } else {
      await ApiService.until(() => {
        return ApiService.isWaitingOauth === false;
      });
    }
    return Vue.axios.get(resource);
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @param config
   * @returns {*}
   */
  async post(resource, params, config = {}) {
    if (resource.includes("oauth/token")) {
      ApiService.isWaitingOauth = true;
    } else {
      await ApiService.until(() => {
        return ApiService.isWaitingOauth === false;
      });
    }
    return Vue.axios.post(resource, params, config);
  },

  until(conditionFunction) {
    const poll = (resolve) => {
      if (conditionFunction()) resolve();
      else setTimeout(() => poll(resolve), 300);
    };
    return new Promise(poll);
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, params) {
    return new Promise((resolve) => {
      setTimeout(() => {
        return resolve(Vue.axios.post(resource, params));
      }, 100);
    });
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    return Vue.axios.put(resource, params);
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource) {
    return Vue.axios.delete(resource).catch((error) => {
      throw new Error(`[RWV] ApiService ${error}`);
    });
  },
};

export default ApiService;
