import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";
import { RESET_LAYOUT_CONFIG } from "@/core/services/store/config.module";
import vSelect from "vue-select";
Vue.config.productionTip = false;

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins
import i18n from "@/core/plugins/vue-i18n";
import vuetify from "@/core/plugins/vuetify";
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/apexcharts";
import "@/core/plugins/treeselect";
import "@/core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";
import LAUtils from "@/core/services/LAUtils.service";
import VueSweetalert2 from "vue-sweetalert2";

import { initToaster } from "@/core/services/the-toaster";
import { FILL_USER, IDDLE_CHECKER, REFRESH_TOKEN, UPDATE_WHITE_LABEL } from "@/core/services/store/auth.module";
import axios from "axios";
import { GET_BUNDLES } from "@/core/services/store/bundle.module";
import aes from "vue-crypt/src/aes";
import { INIT_CART } from "@/core/services/store/cart.module";
import { GET_CONTENTS } from "@/core/services/store/contents.module";
import { GET_TAGS_FULL_LIST } from "@/core/services/store/admin-token-tags.module";
/** @var LAUtils $laUtils **/

Vue.prototype.$laUtils = LAUtils;
initToaster();
// API service init
ApiService.init();

let isProduction = process.env.NODE_ENV === "production";
Vue.config.devtools = !isProduction;
Vue.config.debug = !isProduction;
Vue.config.silent = isProduction;

// Remove this to disable mock API
// MockService.init();
// reset config to initial state

aes.setKey("A%D*G-KaPdSgVkYp3s6v9y$B&E(H+MbQeThWmZq4t7w!z%C*F-J@NcRfUjXn2r5u");
store.dispatch("auth/" + REFRESH_TOKEN);
store.dispatch("bundle/" + GET_BUNDLES);
store.dispatch("cart/" + INIT_CART);
store.dispatch(RESET_LAYOUT_CONFIG).then(() => {
  store.dispatch("auth/" + FILL_USER).then(() => {
    store
      .dispatch("auth/" + UPDATE_WHITE_LABEL, null)
      .finally(() => {})
      .then(() => {})
      .catch(() => {
        // router.push({ name: "auth-signin" });
      });
  });
});

router.beforeEach((to, from, next) => {
  // Ensure we checked auth before each page load.
  // Scroll page to top on every route change
  if (to.name !== from.name) {
    next();
  }
  store.dispatch("auth/" + IDDLE_CHECKER);
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

axios;
let VueScrollTo = require("vue-scrollto");
Vue.use(VueScrollTo);
Vue.use(VueSweetalert2);
Vue.component("vue-select", vSelect);
new Vue({
  router,
  store,
  i18n,
  vuetify,

  render: (h) => h(App),
}).$mount("#app");
