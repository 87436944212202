// action types

import ApiService from "@/core/services/api.service";
import TheToaster from "@/core/services/the-toaster";
import aes from "vue-crypt/src/aes";

export const CART_ADD = "CART_ADD";
export const CART_REMOVE = "CART_REMOVE";
export const CART_PURGE = "CART_PURGE";
export const CART_ADD_FROM_CONTENT = "CART_ADD_FROM_CONTENT";
export const INIT_CART = "INIT_CART";

const state = {
  cart: [],
};

const getters = {
  cart(state) {
    return state.cart;
  },
  cartTotal(state) {
    let res = state.cart.reduce((acumulador, elem, index, array) => {
      return acumulador + elem.price * elem.quantity;
    }, 0);
    return (Math.round((res + Number.EPSILON) * 100) / 100).toFixed(2);
  },
  cartTotalDiscount(state) {
    let res = state.cart.reduce((acumulador, elem, index, array) => {
      return acumulador + (elem.price - elem.discountValue) * elem.quantity;
    }, 0);
    return (Math.round((res + Number.EPSILON) * 100) / 100).toFixed(2);
  },
};

const actions = {
  [INIT_CART](context) {
    let strfy = window.localStorage.getItem("cart");
    if (strfy === null) return null;
    let d = JSON.parse(aes.decrypt(strfy));
    d.forEach((elem) => {
      context.state.cart.push(elem);
    });
  },
  [CART_ADD_FROM_CONTENT](context, contentId) {
    let res = context.state.cart.filter((a) => a.id === contentId);
    if (res.length === 0) {
      ApiService.get("bundle/from-content/" + contentId)
        .then(({ data }) => {
          context.commit(CART_ADD, { item: data[0], quantity: 1 });
        })
        .catch(() => {});
    } else {
      context.commit(CART_ADD, { item: res[0], quantity: 1 });
    }
  },
  [CART_ADD](context, item) {
    context.commit(CART_ADD, { item, quantity: 1 });
  },
  [CART_REMOVE](context, item) {
    context.commit(CART_ADD, { item, quantity: -1 });
  },
  [CART_PURGE](context, item) {
    context.commit(CART_PURGE, { item, quantity: -1 });
  },
};

const mutations = {
  [CART_PURGE](state, { item }) {
    state.cart = state.cart.filter((elem) => elem.id !== item.id);
    let strfy = JSON.stringify(state.cart);
    window.localStorage.setItem("cart", aes.encrypt(strfy));
  },
  [CART_ADD](state, { item, quantity }) {
    let selectedElement = null;
    if (quantity > 0) {
      TheToaster.success("GENERAL.CART_UPDATED");
    }
    state.cart.forEach((element, index, array) => {
      if (element.id === item.id) {
        selectedElement = element;
      }
    });
    if (selectedElement === null) {
      selectedElement = item;
      state.cart.push(selectedElement);
    }
    selectedElement.quantity = Math.max(selectedElement.quantity + quantity, 0);
    selectedElement.total = Math.round((selectedElement.price * selectedElement.quantity + Number.EPSILON) * 100) / 100;
    selectedElement.totalDiscount =
      Math.round(((selectedElement.price - selectedElement.discountValue) * selectedElement.quantity + Number.EPSILON) * 100) / 100;
    selectedElement.total = selectedElement.total.toFixed(2);
    selectedElement.totalDiscount = selectedElement.totalDiscount.toFixed(2);

    let strfy = JSON.stringify(state.cart);
    window.localStorage.setItem("cart", aes.encrypt(strfy));
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
