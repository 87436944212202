import ApiService from "@/core/services/api.service";
import BearerService from "@/core/services/bearer.service";
import merge from "deepmerge";
import store from "@/core/services/store/index";
import router from "./../../../router";
import { OVERRIDE_PAGE_LAYOUT_CONFIG } from "@/core/services/store/config.module";
import HtmlClass from "@/core/services/htmlclass.service";
import objectPath from "object-path";
import { COMMIT_FREE_CONTENT } from "@/core/services/store/free-content.module";
import { ADD_BODY_CLASSNAME } from "@/core/services/store/htmlclass.module";

// action types
export const FILL_USER = "fillUser";
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const REFRESH_TOKEN = "refreshToken";
export const LOGOUT = "logout";
export const REGISTER_USER = "registerUser";
export const RESET_PASSWORD = "resetPassword";
export const UPDATE_WHITE_LABEL = "updateWhiteLabel";
export const SET_WHITE_LABEL = "setWhiteLabel";
export const WHITE_LABEL = "whiteLabel";
export const UPDATE_USER = "updateUser";
export const IDDLE_CHECKER = "iddleChecker";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setAuth";
export const SET_USER = "setUser";

const state = {
  user: {
    id: "",
    name: "",
    surname: "",
    email: "",
    hash: "",
    phone: "",
    token: "",
    birth_date: "",
    preferred_white_label: "",
    preferred_white_label_id: 0,
    preferred_language: "",
    photo_url: "",
    white_label_enabled: false,
    white_label_name: "",
    white_label_photo_url: "",
    white_label_slug: "",
    white_label_commission: "",
    white_label_global_discount: "",
    email_verified_at: "",
    date_format: "",
  },

  refreshTokenServiceRunning: false,
  idleFn: null,
};

const getters = {
  currentUser(state) {
    return state.user;
  },

  currentUserFullName(state) {
    return state.user.name + " " + (state.user.surname || "");
  },
  currentUserPicture(state) {
    return state.user.photo_url ? process.env.VUE_APP_API_URL + state.user.photo_url + "?r=" + Math.random() : "/media/svg/icons/General/User.svg";
  },
  userWhiteLabelPicture(state) {
    return state.user.white_label_photo_url
      ? process.env.VUE_APP_API_URL + state.user.white_label_photo_url + "?r=" + Math.random()
      : "/media/svg/icons/Design/Pixels.svg";
  },
  currentWhiteLabel() {
    return window.localStorage.getItem(WHITE_LABEL) || process.env.VUE_APP_DEFAULT_BRAND;
  },
  getSettableWhiteLabel() {
    let res = window.sessionStorage.getItem(SET_WHITE_LABEL);
    if (res === null || res === "null" || res === undefined || res === "undefined") {
      window.sessionStorage.removeItem(SET_WHITE_LABEL);
      return null;
    }
    return window.sessionStorage.getItem(SET_WHITE_LABEL);
  },
};

const actions = {
  [LOGIN](context, credentials) {
    credentials = merge(credentials, {
      grant_type: process.env.VUE_APP_GRANT_TYPE,
      client_id: process.env.VUE_APP_CLIENT_ID,
      client_secret: process.env.VUE_APP_CLIENT_SECRET,
      scope: process.env.VUE_APP_SCOPE,
    });

    return new Promise((loginResolver, loginRejects) => {
      // eslint-disable-next-line prettier/prettier

      ApiService.post("oauth/token", credentials)
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          store.dispatch("freeContent/" + COMMIT_FREE_CONTENT);
          ApiService.get("me")
            .then(({ data }) => {
              context.commit(SET_USER, data);
              store.dispatch("auth/" + UPDATE_WHITE_LABEL);
              loginResolver(data);
            })
            .catch(({ response }) => {
              loginRejects(response);
            });
        })
        .catch(({ response }) => {
          loginRejects(response);
        });
    });
  },
  [IDDLE_CHECKER](context) {
    if (process.env.NODE_ENV === "production") {
      clearTimeout(context.state.idleFn);
      context.state.idleFn = setTimeout(() => {
        clearTimeout(context.state.idleFn);
        context.commit(PURGE_AUTH);
        router.push({ name: "auth-signin" });
      }, 900000);
    }
  },

  [UPDATE_USER](context, { payload, suffix = "" }) {
    return new Promise((updateResolver, updateRejecter) => {
      // eslint-disable-next-line prettier/prettier
      ApiService.post("me" + suffix, payload)
        .then(({ data }) => {
          if (suffix === "/profile-image") {
            data.photo_url = data.photo_url + "?r=" + Math.random();
          }
          context.commit(SET_USER, data);
          updateResolver(data);
        })
        .catch(({ response }) => {
          updateRejecter(response);
        });
    });
  },
  [REFRESH_TOKEN](context) {
    if (!BearerService.isAuthenticated()) return;
    let credentials = {
      grant_type: process.env.VUE_APP_REFRESH_GRANT_TYPE,
      client_id: process.env.VUE_APP_CLIENT_ID,
      client_secret: process.env.VUE_APP_CLIENT_SECRET,
      scope: process.env.VUE_APP_SCOPE,
      refresh_token: BearerService.getRefreshToken(),
    };
    return new Promise((resolve, reject) => {
      ApiService.post("oauth/token/refresh", credentials)
        .then(({ data }) => {
          context.commit(SET_AUTH, data);

          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  [LOGOUT](context, reload = true) {
    if (reload && BearerService.getAccessToken()) {
      context.commit(PURGE_AUTH);
      store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
      setTimeout(() => {
        location.reload();
      }, 1000);
    } else {
      context.commit(PURGE_AUTH);
    }
  },

  [REGISTER_USER](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("register", credentials)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  [VERIFY_AUTH](context) {
    // eslint-disable-next-line prettier/prettier
    if (!BearerService.isAuthenticated() || context.state.refreshTokenServiceRunning) {
      return;
    }
    context.state.refreshTokenServiceRunning = true;
    setTimeout(() => {
      context.state.refreshTokenServiceRunning = false;
      this.dispatch("auth/" + REFRESH_TOKEN);
    }, BearerService.getExpiresIn() * 950);
  },

  [RESET_PASSWORD](context, payload) {
    return ApiService.post("reset-password", payload).then(({ data }) => {
      return data;
    });
  },
  [FILL_USER](context) {
    let user = BearerService.getUser();
    if (BearerService.isAuthenticated() && user) {
      context.commit(FILL_USER, user);
    }
    return new Promise((resolve) => {
      resolve(user);
    });
  },

  [UPDATE_WHITE_LABEL](context, whiteLabel) {
    if (whiteLabel !== null) {
      context.commit(SET_WHITE_LABEL, whiteLabel);
    }
    // eslint-disable-next-line prettier/prettier
    whiteLabel = whiteLabel || window.localStorage.getItem(WHITE_LABEL) || process.env.VUE_APP_DEFAULT_BRAND;
    // eslint-disable-next-line prettier/prettier
    if (whiteLabel === window.localStorage.getItem(WHITE_LABEL) && whiteLabel === process.env.VUE_APP_DEFAULT_BRAND) {
      return new Promise(() => {});
    }

    return new Promise((resolve, reject) => {
      ApiService.get("white-label/" + whiteLabel)
        .then(({ data }) => {
          context.commit(UPDATE_WHITE_LABEL, whiteLabel);
          store.dispatch(OVERRIDE_PAGE_LAYOUT_CONFIG, data).then(() => {
            HtmlClass.updateBgImage(objectPath.get(data, "self.body.background-image"));
          });
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(UPDATE_WHITE_LABEL, process.env.VUE_APP_DEFAULT_BRAND);
          reject(response);
        });
    });
  },
};

const mutations = {
  [FILL_USER](state, user) {
    Object.entries(user).forEach(([key, value]) => {
      state.user[key] = value;
    });
  },

  [SET_USER](state, user) {
    BearerService.saveUser(user);
    Object.entries(user).forEach(([key, value]) => {
      state.user[key] = value;
    });
    if (state.user.preferred_white_label) {
      window.localStorage.setItem(WHITE_LABEL, state.user.preferred_white_label);
    }
  },
  [SET_AUTH](state, token) {
    BearerService.saveToken(token);
    store.dispatch("auth/" + VERIFY_AUTH);
  },
  [SET_WHITE_LABEL](state, whiteLabel) {
    // eslint-disable-next-line prettier/prettier
    window.sessionStorage.setItem(SET_WHITE_LABEL, whiteLabel);
  },
  [UPDATE_WHITE_LABEL](state, whiteLabel) {
    // eslint-disable-next-line prettier/prettier
    window.localStorage.setItem(WHITE_LABEL, whiteLabel);
  },
  [PURGE_AUTH](state) {
    for (let prop in state.user) {
      state.user[prop] = "";
    }
    BearerService.destroyToken();
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
