import Vue from "vue";
import i18n from "@/core/plugins/vue-i18n";

let toasterVm = new Vue();
const delay = 6000;
const toaster = "b-toaster-top-center";

function initToaster() {
  toasterVm = new Vue();
}
function handle(msg, translate, variant) {
  if (translate) {
    msg = i18n.tc(msg);
  }
  toasterVm.$bvToast.hide();
  toasterVm.$bvToast.toast(msg, {
    title: msg, // i18n.tc("GENERAL.ATTENTION") + "!",
    autoHideDelay: delay,
    variant: variant,
    toaster: toaster,
    headerClass: "rounded  lead mt-4 py-4 pl-6",
    bodyClass: "hidden",
    solid: true,
    appendToast: false,
  });
}
function handleDialog(msg, translate, variant, title) {
  if (translate) {
    msg = i18n.tc(msg);
  }
  Vue.swal(i18n.tc(title), msg, variant);
}
const TheToaster = {
  dialogError(msg, translate = true) {
    handleDialog(msg, translate, "error", "GENERAL.ATTENTION");
  },
  error: (msg, translate = true) => {
    handle(msg, translate, "danger");
  },
  warning: (msg, translate = true) => {
    handle(msg, translate, "warning");
  },
  success: (msg, translate = true) => {
    handle(msg, translate, "success");
  },
  info: (msg, translate = true) => {
    handle(msg, translate, "info");
  },
  saving: () => {
    handle("GENERAL.SAVING", true, "success");
  },
};
export { initToaster };

export default TheToaster;
