// action types

import ApiService from "@/core/services/api.service";

export const GET_BOUGHT_CONTENTS = "getBoughtContents";
export const GET_BOUGHT_TOKENS = "getBoughtTokens";
export const INVITE_ATTENDEE = "inviteAttendee";

export const INVITE_ATTENDEE_FROM_CUSTOMER = "inviteAttendeeFromCustomer";
export const REVOKE_ATTENDEE_FROM_CUSTOMER = "REVOKE_ATTENDEE_FROM_CUSTOMER";

const state = {
  boughtContents: [],
  boughtTokens: [],
  pagination: {
    total: 0,
    count: 0,
    per_page: 0,
    current_page: 0,
    total_pages: 0,
  },
  manageTokensFilter: {
    search: null,
    tag: { id: null, name: "" },
    perPage: 30,
    content: "",
    token: "",
    status: "all",
  },
};

const getters = {
  boughtContents(state) {
    return state.boughtContents;
  },
  boughtTokens(state) {
    return state.boughtTokens;
  },
  boughtTokensPagination(state) {
    return state.pagination;
  },
  manageTokensFilter(state) {
    return state.manageTokensFilter;
  },
};

const actions = {
  [GET_BOUGHT_CONTENTS](context) {
    return new Promise(function (resolve, reject) {
      if (context.state.boughtTokens.length > 0) {
        resolve(context.state.boughtTokens);
      } else {
        ApiService.get("admin-tokens/bought-contents")
          .then(({ data }) => {
            context.commit(GET_BOUGHT_CONTENTS, data);
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      }
    });
  },
  [GET_BOUGHT_TOKENS](context, query) {
    return ApiService.query("admin-tokens", query)
      .then(({ data }) => {
        context.commit(GET_BOUGHT_TOKENS, data);
      })
      .catch(() => {});
  },
  [INVITE_ATTENDEE_FROM_CUSTOMER](context, { email, token, name }) {
    return ApiService.post("tokens/invite-from-customer", {
      email,
      token,
      name,
    }).catch(() => {});
  },
  [REVOKE_ATTENDEE_FROM_CUSTOMER](context, { email, token, name }) {
    return ApiService.post("tokens/revoke-from-customer", {
      token,
    }).catch(() => {});
  },
  [INVITE_ATTENDEE](context, { email, token, name }) {
    return ApiService.post("tokens/invite", {
      email,
      token,
      name,
    }).catch(() => {});
  },
};

const mutations = {
  [GET_BOUGHT_CONTENTS](state, payload) {
    state.boughtContents.splice(0, state.boughtContents.length);
    payload.map((item) => state.boughtContents.push(item));
  },
  [GET_BOUGHT_TOKENS](state, payload) {
    state.boughtTokens.splice(0, state.boughtTokens.length);

    payload.data.map((item) => state.boughtTokens.push(item));
    state.pagination.total = payload.pagination.total;
    state.pagination.count = payload.pagination.count;
    state.pagination.per_page = payload.pagination.per_page;
    state.pagination.current_page = payload.pagination.current_page;
    state.pagination.total_pages = payload.pagination.total_pages;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
