import Vue from "vue";
import VueI18n from "vue-i18n";

// Localisation language list
import { locale as en } from "@/core/config/i18n/en";
import { locale as es } from "@/core/config/i18n/es";
import { locale as de } from "@/core/config/i18n/de";
import { locale as it } from "@/core/config/i18n/it";
import { locale as fr } from "@/core/config/i18n/fr";
import { locale as pt_BR } from "@/core/config/i18n/pt_BR";
import i18nService from "@/core/services/i18n.service";

Vue.use(VueI18n);

let messages = {};
// messages = { ...messages, en, es, fr, pt_BR };
messages = { ...messages, pt_BR, en, es, de, it, fr };

// get current selected language
const lang = i18nService.getActiveLanguage();

// Create VueI18n instance with options
const i18n = new VueI18n({
  fallbackLocale: i18nService.defaultLanguage,
  locale: lang, // set locale
  messages, // set locale messages
});

export default i18n;
