import Vue from "vue";
import Vuex from "vuex";

import auth from "./auth.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";
import token from "./token.module";
import report from "./report.module";
import adminTokens from "./admin-tokens.module";
import contents from "./contents.module";
import adminTokenTags from "./admin-token-tags.module";
import freeContent from "./free-content.module";
import bundle from "./bundle.module";
import cart from "./cart.module";
import myOrders from "./my-orders.module";
import giftsSent from "./gifts-sent.module";
import checkout from "./checkout.module";
import contact from "./contact.module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    cart,
    contact,
    checkout,
    myOrders,
    giftsSent,
    contents,
    token,
    htmlClass,
    report,
    config,
    breadcrumbs,
    adminTokens,
    adminTokenTags,
    freeContent,
    bundle,
  },
});
