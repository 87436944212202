// action types

import ApiService from "@/core/services/api.service";

export const REDEEM_TOKEN = "redeemToken";
export const GET_PRESET_DATA_CONTENT = "GET_PRESET_DATA_CONTENT";
export const GET_SAVE_DATA_CONTENT = "GET_SAVE_DATA_CONTENT";
export const GET_RESULT_DATA_CONTENT = "GET_RESULT_DATA_CONTENT";
export const UPDATE_SAVE_DATA_CONTENT = "updateSaveData";
export const GET_ATTENDEE_TOKENS = "getAttendeeTokens";
export const GET_ATTENDEE_PROGRESS = "getAttendeeProgress";
export const DOWNLOAD_CONTENT = "downloadContent";
export const GET_DOWLOADABLE_CONTENT = "GET_DOWLOADABLE_CONTENT";
export const REDEEM_TOKEN_UNAUTHENTICATED = "REDEEM_TOKEN_UNAUTHENTICATED";
export const CAN_REDEEM_TOKEN_UNAUTHENTICATED = "CAN_REDEEM_TOKEN_UNAUTHENTICATED";

const state = {
  currentType: "",
  allTokens: [],
  paginator: {
    total: 5,
    count: 2,
    per_page: 2,
    current_page: 2,
    total_pages: 3,
  },
  perPage: 20,
  attendeeProgress: [],
  lastRedeemedToken: "",
};

const getters = {
  currentTokens(state) {
    return state.allTokens;
  },
  currentTokenPaginator(state) {
    return state.paginator;
  },
  attendeeProgress(state) {
    return state.attendeeProgress;
  },
  lastRedeemedToken(state) {
    return state.lastRedeemedToken;
  },
};

const actions = {
  [REDEEM_TOKEN]: async (context, token) => {
    return new Promise((onSuccess, onFail) => {
      ApiService.post("tokens/redeem", { token })
        .then((response) => {
          context.commit(REDEEM_TOKEN, token);
          context.dispatch("token/" + GET_ATTENDEE_PROGRESS);
          onSuccess(response);
        })
        .catch((response) => {
          onFail(response);
        });
    });
  },
  [CAN_REDEEM_TOKEN_UNAUTHENTICATED]: async (context, token) => {
    return new Promise((onSuccess, onFail) => {
      ApiService.post("tokens/can-redeem-unauthenticated", { token })
        .then((response) => {
          context.commit(REDEEM_TOKEN, token);
          onSuccess(response);
        })
        .catch((response) => {
          onFail(response);
        });
    });
  },
  [REDEEM_TOKEN_UNAUTHENTICATED]: async (context, token) => {
    return new Promise((onSuccess, onFail) => {
      ApiService.post("tokens/redeem-unauthenticated", { token })
        .then((response) => {
          context.commit(REDEEM_TOKEN, token);
          onSuccess(response);
        })
        .catch((response) => {
          onFail(response);
        });
    });
  },
  [GET_ATTENDEE_TOKENS]: async (context, { type, page, selectedProgress }) => {
    return new Promise((onSuccess, onFail) => {
      ApiService.query("tokens/attendee", {
        page,
        selected_progress: selectedProgress,
        per_page: context.state.perPage,
        type: type,
      })
        .then((response) => {
          context.commit(GET_ATTENDEE_TOKENS, { response, type });
          onSuccess(context.state.allTokens);
        })
        .catch((response) => {
          onFail(response);
        });
    });
  },
  [GET_ATTENDEE_PROGRESS](context) {
    return new Promise((onSuccess, onFail) => {
      ApiService.get("tokens/progress")
        .then((response) => {
          context.commit(GET_ATTENDEE_PROGRESS, response.data);
          onSuccess(context.state.all);
        })
        .catch((response) => {
          onFail(response);
        });
    });
  },
  [GET_PRESET_DATA_CONTENT](context, token) {
    return ApiService.get(`contents/get-data/${token}`);
  },
  [GET_DOWLOADABLE_CONTENT](context, { codename, filename }) {
    return ApiService.post(`contents/download/${codename}/${filename}`, {}, { responseType: "arraybuffer" });
  },
  [DOWNLOAD_CONTENT](context, { codename, filename }) {
    return ApiService.download(`contents/download/${codename}/${filename}`, {}, filename, "");
  },
  [GET_SAVE_DATA_CONTENT](context, token) {
    return ApiService.get(`contents/get-save-data/${token}`);
  },
  [GET_RESULT_DATA_CONTENT](context, { token }) {
    return ApiService.get(`contents/get-result-data/${token}`);
  },
  [UPDATE_SAVE_DATA_CONTENT](context, { save_content, token }) {
    return ApiService.post(`contents/update-save-data/${token}`, {
      save_content,
    });
  },
};

const mutations = {
  [REDEEM_TOKEN](state, lastRedeemedToken) {
    state.lastRedeemedToken = lastRedeemedToken;
  },
  [GET_ATTENDEE_PROGRESS](state, payload) {
    state.attendeeProgress.splice(0, state.attendeeProgress.length);
    payload.map((elem) => {
      state.attendeeProgress.push(elem);
    });
  },
  [GET_ATTENDEE_TOKENS](state, { response, type }) {
    state.currentType = type;
    state.allTokens.splice(0, state.allTokens.length);
    state.paginator = response.data.pagination;

    response.data.data.map((elem) => {
      state.allTokens.push(elem);
    });
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
