import Vue from "vue";
import Router from "vue-router";

// const originalPush = Router.prototype.push;
// Router.prototype.push = (location) => {
//   return originalPush.call(this, location).catch((err) => err);
// };

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      redirect: "/dashboard",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("@/view/pages/main-dashboard/MainDashboard"),
        },
        {
          path: "/redeem",
          name: "redeem",
          component: () => import("@/view/pages/redeem/redeem"),
        },
        {
          path: "content",
          redirect: "/bundle/list",
          component: () => import("@/view/pages/generic/FadeUp"),
          children: [
            {
              path: "run/:token",
              name: "content-run",
              component: () => import("@/view/pages/contents/RunContent/RunContent.vue"),
            },
          ],
        },
        {
          path: "tokens",
          redirect: "/tokens/manage",
          component: () => import("@/view/pages/generic/FadeUp"),
          children: [
            {
              path: "manage",
              name: "tokens-manage",
              component: () => import("@/view/pages/tokens/ManageTokens/ManageTokens"),
            },
            {
              path: "tags",
              name: "tokens-tag",
              component: () => import("@/view/pages/tokens/token-tags/token-tags"),
            },
            // {
            //   path: "manage-tag/:id",
            //   name: "tokens-manage-tag",
            //   component: () => import("@/view/pages/tokens/token-tags/manage-tag"),
            // },
          ],
        },
        {
          path: "groups",
          redirect: "/groups/my-groups",
          component: () => import("@/view/pages/generic/FadeUp"),
          children: [
            {
              path: "my-groups",
              name: "groups-my-groups",
              component: () => import("@/view/pages/groups/my-groups"),
            },
            {
              path: "manage",
              name: "groups-manage",
              component: () => import("@/view/pages/groups/my-groups"),
            },
          ],
        },

        {
          path: "profile",
          redirect: "/profile/main",
          component: () => import("@/view/pages/generic/FadeUp"),
          children: [
            {
              path: "main",
              name: "profile-main",
              component: () => import("@/view/pages/profile/Profile"),
            },
          ],
        },

        {
          path: "orders",
          redirect: "/orders/my-orders",
          component: () => import("@/view/pages/generic/FadeUp"),
          children: [
            {
              path: "my-orders",
              name: "my-orders",

              component: () => import("@/view/pages/commerce/CommerceMyOrders/CommerceMyOrders"),
            },
            {
              path: " details/:id",
              name: "my-orders-detail",

              component: () => import("@/view/pages/commerce/CommerceOrderDetails/CommerceOrderDetails"),
            },
          ],
        },
        {
          path: "gifts",
          redirect: "/gifts/my-gifts",
          component: () => import("@/view/pages/generic/FadeUp"),
          children: [
            {
              path: "my-gifts",
              name: "my-gifts",

              component: () => import("@/view/pages/gifts/my-gifts"),
            },
          ],
        },
      ],
    },
    {
      path: "/guest",
      redirect: "/dashboard",
      component: () => import("@/view/layout/LayoutGuest"),
      children: [
        {
          path: "content",
          component: () => import("@/view/pages/generic/FadeUp"),
          children: [
            {
              path: "run/:codename",
              name: "free-content-run",
              component: () => import("@/view/pages/free-contents/RunFreeContent/RunFreeContent.vue"),
            },
          ],
        },
      ],
    },
    {
      path: "/store",
      redirect: "/store/life-academy",
      component: () => import("@/view/layout/LayoutGuest"),
      children: [
        {
          path: ":whiteLabelSlug",
          name: "store",

          component: () => import("@/view/pages/white-label/WhiteLabel/WhiteLabel"),
        },
      ],
    },

    {
      path: "/general",
      redirect: "/",
      component: () => import("@/view/layout/LayoutGuest"),
      children: [
        {
          path: "redeem",
          name: "general-redeem",
          component: () => import("@/view/pages/tokens/RedeemTokenUnauthenticated/RedeemTokenUnauthenticated.vue"),
        },

        {
          path: "privacy",
          name: "general-privacy",
          component: () => import("@/view/pages/general/Privacy.vue"),
        },
        {
          path: "contact",
          name: "general-contact",
          component: () => import("@/view/pages/general/Contact.vue"),
        },
        {
          path: "gdpr",
          name: "general-gdpr",
          component: () => import("@/view/pages/general/GDPR.vue"),
        },
        {
          path: "eula",
          name: "general-eula",
          component: () => import("@/view/pages/general/EULA.vue"),
        },
      ],
    },
    {
      path: "/login",
      redirect: "/auth/signin",
    },
    {
      path: "/signin",
      redirect: "/auth/signin",
    },
    {
      path: "/sign-in",
      redirect: "/auth/signin",
    },
    {
      path: "/register",
      redirect: "/auth/signup",
    },
    {
      path: "/signup",
      redirect: "/auth/signup",
    },
    {
      path: "/sign-up",
      redirect: "/auth/signup",
    },
    {
      path: "/forgot",
      redirect: "/auth/forgot",
    },
    {
      path: "/reset",
      redirect: "/auth/forgot",
    },
    {
      path: "/auth",
      name: "auth",
      redirect: "/auth/signin",
      component: () => import("@/view/pages/generic/FadeUp"),

      children: [
        {
          name: "auth-signin",
          path: "signin",
          component: () => import("@/view/pages/auth/Login"),
        },
        {
          name: "auth-signup",
          path: "signup",
          component: () => import("@/view/pages/auth/Login"),
        },
        {
          name: "auth-forgot",
          path: "forgot",
          component: () => import("@/view/pages/auth/Login"),
        },
      ],
    },
    {
      path: "*",
      redirect: "/error/404",
    },
    {
      // the 404 route, when none of the above matches
      path: "/error",

      component: () => import("@/view/pages/generic/FadeUp"),
      children: [
        {
          name: "404",
          path: "404",
          component: () => import("@/view/pages/error/Error-404"),
        },
        {
          name: "500",
          path: "500",
          component: () => import("@/view/pages/error/Error-500"),
        },
      ],
    },
  ],
});
